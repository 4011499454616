require('es6-promise').polyfill();
import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters';

Vue.use(Vuex)

// Modules
import ModuleAuth from '@/store/modules/auth';
import ModuleLoader from '@/store/modules/loader';
import ModuleUser from '@/store/modules/user';
import ModuleDeviceType from '@/store/modules/device/type';
import ModuleApp from '@/store/modules/app'

export default new Vuex.Store({
  modules: {
    auth:   ModuleAuth,
    loader: ModuleLoader,
    user:   ModuleUser,
    deviceType: ModuleDeviceType,
    app: ModuleApp,
  },
  getters,
});
