import themes from "@/setup/themes";

const state = {
  theme: function(){
    const provider = process.env.VUE_APP_PROVIDER;
    if(provider && themes && themes[provider]){
      return themes[provider];
    }
    return themes['default'];
  },
  showExpired: false
};


export default {
  namespaced: true,
  state,
  mutations: {
    toggleShowExpired(state) {
      state.showExpired = !state.showExpired
    }
  }
};
