import Vue from 'vue'

export default {
  namespaced: true,
  state:      {
    id:          null,
    email:       null,
    name:        null,
    surname:     null,
    permissions: [],
    provider:    {},
    modules:     {},
  },
  mutations:  {
    push(state, data) {
      state.id          = data.id;
      state.email       = data.email;
      state.name        = data.name;
      state.permissions = data.permissions;
      state.modules     = data.modules;
    },
    pushProvider(state, data) {
      if (!data.provider) {
        return;
      }

      state.provider.id   = data.provider.id;
      state.provider.name = data.provider.name;
    },
  },
  getters:    {
    has:         state => !!state.id,
    email:       state => state.email,
    name:        state => state.name,
    permissions: state => state.permissions || [],
    provider:    state => state.provider || {},
    modules:     state => state.modules || {},
  },
  actions:    {
    get({commit}) {
      return Vue
        .prototype.$http.get('/auth/user')
        .then(response => {
          commit('push', response.data.data);
          commit('pushProvider', response.data.data);
          return response;
        });
    },

    reload() {
      return Vue
        .prototype.$http.get('/auth/user')
        .then(response => {
          return response;
        });
    },

    audit({commit}, data) {
      commit('pushAudit', data);
    },
  },
}
