import {Auditor} from '@/constants/permissions'

const bodyClass = 'body-bg offcanvas-right header-fixed subheader-enabled';

export const routes = [
  {
    path:      '/',
    name:      'Root',
    component: () => import(/* webpackChunkName: "Layout" */ '@/views/DefaultLayout.vue'),
    hidden:    true,
    meta:      {
      auth:     true,
      redirect: permission => {
        switch (permission) {
          case Auditor:
            return 'Auditor';
/*          case Service:
            return 'Service';*/
          default:
            return null;
        }
      },
    },
    children:  [
      {
        path:      "/revizor",
        name:      "Auditor",
        component: { render: (c) => c('router-view') },
        redirect: '/revizor/udalosti',
        meta:      {
          title:       'Revízor',
          root:        true,
          auth:        true,
          keepAlive:   true,
          bodyClass: bodyClass,
          permissions: [Auditor]
        },
        children: [
          {
            path: "udalosti",
            name: "EventList",
            component: () => import(/* webpackChunkName: "Auditor" */ '@/views/pages/PageAuditor'),
            meta: {
              title: 'Zoznam eventov',
              root: true,
              auth: true,
              keepAlive: true,
              bodyClass: 'test',
              permissions: [Auditor]
            },
          },
          {
            path: 'udalosti/:event_id',
            name: 'EventDetail',
            component: () => import(/* webpackChunkName: "Auditor" */ '@/views/pages/PageAuditorEventDetail'),
            meta:      {
              title:       'Revízor',
              root:        false,
              auth:        true,
              keepAlive:   true,
              permissions: [Auditor]
            },
          },
          {
            path: 'udalosti/:event_id/hid-scan',
            name: 'FastHidScan',
            component: () => import(/* webpackChunkName: "Auditor" */ '@/views/pages/PageFastHidScan.vue'),
            meta:      {
              title:       'Revízor Fast Scan',
              root:        false,
              auth:        true,
              keepAlive:   true,
              permissions: [Auditor]
            },
          }
        ],
      },
    ]
  },
  {
    path:      '/prihlasenie',
    name:      'Login',
    component: () => import(/* webpackChunkName: "AuthLogin" */ '@/views/auth/AuthLogin.vue'),
    meta:      {
      title: 'Prihlásenie'
    }
  },
  {
    path:      '/oops',
    name:      'Oops',
    component: () => import(/* webpackChunkName: "Oops" */ '@/views/error/ErrorOops.vue'),
    meta:      {
      only:  true,
      title: 'Oops...'
    }
  },
  {
    path:      '*',
    name:      'NotFound',
    component: () => import(/* webpackChunkName: "Oops" */ '@/views/error/ErrorOops.vue'),
    meta:      {
      only:  true,
      title: 'Stránka sa nenašla'
    }
  },
];
