import Vue from 'vue'
import VueRouter from 'vue-router'
import VueBodyClass from 'vue-body-class'
import LocalStorageToken from '@/services/storage/local/token'
import {routes} from '@/router/map'
import store from '@/store'

Vue.use(VueRouter)

const title     = store.getters.theme.appName+' | Servisná a revízna aplikácia';
const bodyClass = 'body-bg offcanvas-right header-fixed subheader-enabled';

const router = new VueRouter({
  mode: 'history',
  routes
})

const vueBodyClass             = new VueBodyClass(routes);
const LocalStorageTokenService = LocalStorageToken.getService();
let permissions                = null;

router.beforeEach(async (to, from, next) => {
  // Title
  document.title = title;
  if (to.matched.some(record => record.meta.title)) {
    document.title = title + ' - ' + to.meta.title;
  }

  // Body Class
  if (to.matched.some(record => record.meta.root) && !to.matched.some(record => record.meta.bodyClass)) {
    to.meta.bodyClass = bodyClass;
  }
  vueBodyClass.guard(to, next);

  if (!to.matched.some(record => record.meta.only)) {
    // Redirect auth
    const token = LocalStorageTokenService.getAccessToken();
    if (to.matched.some(record => record.meta.auth) && to.meta.auth) {
      if (token) {
        // Redirect not-auth pages to auth
        if (!to.matched.some(record => record.meta.auth) && token) {
          return next({
              name: 'Root'
            }
          );
        } else {
          // Auth - base data
          if (to.matched.some(record => record.meta.auth) && !store.getters['user/has']) {
            await store.dispatch('user/get')
              .then(response => permissions = response.data.data.permissions)
              .catch(() => {
                // go to login
                LocalStorageTokenService.clearToken();
                return next({
                    name: 'Login'
                  }
                );
              }).finally(() => {
                next();
              });
          }

          // Permissions
          if (permissions === null && store.getters['user/has']) {
            permissions = store.getters['user/permissions'];
          }
          // Redirect by permissions
          if (typeof to.meta.redirect === 'function') {
            permissions.forEach(permission => {
              let redirect = to.meta.redirect(permission);
              if (redirect) {
                return next({
                  name: redirect
                });
              }
            });
          } else {
            // Forbidden permissions
            if (to.matched.some(record => record.meta.permissions)) {
              let permission_forbidden = true;
              to.meta.permissions.forEach(permission => {
                if (permissions.includes(permission)) {
                  permission_forbidden = false;
                  return false;
                }
              });

              if (permission_forbidden === true) {
                return next({
                  name: 'Oops'
                });
              }
            }
          }
        }
      } else {
        return next({
            name: 'Login'
          }
        );
      }
    }
  }
});

export default router;
